// src:- https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/

export const downloadXLS = async (blob, name = 'virbac_file') => {
  // Uncomment if the response doesn't have set the proper 'Accept' header type
  // It is necessary to create a new blob object with mime-type explicitly set
  // var newBlob = new Blob([blob], { type: 'application/vnd.ms-excel' })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(blob)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob)
  var link = document.createElement('a')
  link.href = data
  link.download=`${name}.xlsx`
  link.click()
  setTimeout(function() {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
  }, 100)
}
