'use client'
import { type FC, memo, useMemo } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { mq, vw, designGrid, getIntro22 } from '@/styles'
import { MODALS } from '@/context'
import { useSession, useModal, useToast } from '@/hooks'
import { VIRBAC_STORE, copyTextToClipboard } from '@/utils'
import { useParams } from 'next/navigation'
// import { Figure } from './Figure'

const items = [
  {
    img: {
      src: '/images/svg/icon-receta-digital.svg',
      alt: 'Icon receta digital',
    },
    title: 'Enviar recomendación de producto',
    description: 'Tus clientes recibirán un enlace a la tienda con el producto que les recomiendes por email o SMS',
  },
  {
    img: {
      src: '/images/svg/icon-facebook.svg',
      alt: 'Icon facebook',
    },
    title: 'Publicar invitación en tu Facebook',
    description: 'Publica en Facebook un acceso a la tienda online vinculado directamente a tu clínica',
    url: (clivirbac) => `https://www.facebook.com/sharer/sharer.php?u=${process.env.SITE_ENVIRONMENT === 'staging' ? 'https://store-7g9zgtzv8k.mybigcommerce.com/' : 'https://store.es.virbac.com'}?vet_id=${clivirbac}&display=popup&src=like`
  },
  {
    img: {
      src: '/images/svg/icon-flight.svg',
      alt: 'Icon flight',
    },
    title: 'Enviar enlace por SMS',
    description: 'Escribe un teléfono y envía un enlace de invitación a la tienda para que tus clientes se vinculen automáticamente a tu clínica.',
  },
  {
    img: {
      src: '/images/svg/icon-qr.svg',
      alt: 'Icon QR',
    },
    title: 'Imprimir QR para recomendar producto en papel',
    description: 'Si prefieres utilizar el papel, puedes imprimir estos códigos QR y escribir tu recomendación a mano. A través de este código, tus clientes se vincularán a tu clínica automáticamente.',
  },
  {
    img: {
      src: '/images/svg/icon-clipboard.svg',
      alt: 'Icon clipboard',
    },
    title: 'Copiar enlace en el portapapeles',
    description: 'Pégalo en el WhatsApp, Newsletter, email, o en la web y tus clientes se vincularán a tu clínica automáticamente.',
  },
  {
    img: {
      src: '/images/svg/icon-email.svg',
      alt: 'Icon receta digital',
    },
    title: 'Enviar enlace por email',
    description: 'Escribe un email y envía un enlace de invitación a la tienda para que tus clientes se vinculen automáticamente a tu clínica.',
  }
]

const text = 'Además, podrás enviarles una recomendación de producto o enlace a la tienda a través de cualquiera de los medios disponibles.'

const getClivirbac = (vet_id, vets) => {
  if(Array.isArray(vets)) {
    if(vets.length > 1) return vets.filter(item => item.id === Number(vet_id))[0]?.clivirbac
    else return vets[0].clivirbac
  }
}

export const Share:FC = memo(() => {
  const { toggle:toggleRecommendation } = useModal(MODALS.RECOMMENDATION)
  const { toggle:toggleSMS } = useModal(MODALS.SMS)
  const { toggle:toggleQR } = useModal(MODALS.QR)
  // const { toggle:toggleClipboard } = useModal(MODALS.CLIPBOARD)
  const { toggle:toggleMail } = useModal(MODALS.EMAIL)
  const params = useParams()
  const { user } = useSession()
  const { addToast } = useToast()

  const Clivirbac = useMemo(() => getClivirbac(params.vet_id, user?.vets), [params.vet_id, user?.vets])

  const toggleClipboard = () => {
    copyTextToClipboard(VIRBAC_STORE(Clivirbac))
    addToast({
      message: 'URL de la tienda de Virbac copiado correctamente',
      type: 'success'
    })
  }

  return (
    <Section $dashboardPage={user?.role === 'vet_full_access' || user?.role === 'vet_manager'}>
      {(user?.role === 'vet_full_access' || user?.role === 'vet_manager') ?
        <p>
          Invitando a tus clientes a la tienda online desde esta plataforma quedarán vinculados a tu clínica y ellos a su vez podrán disfrutar de precios y promociones exclusivas. Podrás ver sus pedidos y <strong>recibirás el 15% de todo lo que compren</strong>. {user?.role === 'vet_manager' && text }
        </p> :
        <p>
          Invitando a tus clientes a la tienda online desde esta plataforma quedarán vinculados a tu clínica y ellos a su vez disfrutarán de precios y promociones exclusivas. Puedes enviarles una <button onClick={toggleRecommendation}>recomendación digital</button> o enlace a la tienda <strong>a través de cualquiera de los medios disponibles.</strong>
        </p>
      }
      {(user?.role === 'vet_full_access' || user?.role === 'vet_manager') &&
        <p>
          {user?.role === 'vet_manager' ?
            <>
              También puedes <Link href={params.vet_id ? `/my-vet/${params.vet_id}/user` : '/user'}><strong>crear usuarios</strong></Link> para que el personal de tu clínica tenga acceso a la plataforma.
            </>
            :
            text
          }
        </p>
      }
      <ul>
        <Li>
          <button onClick={toggleRecommendation}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={items[0].img.src} alt={items[0].img.alt} />
            <h3>{items[0].title}</h3>
            <p>{items[0].description}</p>
          </button>
        </Li>
        <Li>
          <a href={items[1].url(Clivirbac)} target='_blank'>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={items[1].img.src} alt={items[1].img.alt} />
            <h3>{items[1].title}</h3>
            <p>{items[1].description}</p>
          </a>
        </Li>
        <Li>
          <button onClick={toggleSMS}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={items[2].img.src} alt={items[2].img.alt} />
            <h3>{items[2].title}</h3>
            <p>{items[2].description}</p>
          </button>
        </Li>
        <Li>
          <button onClick={toggleQR}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={items[3].img.src} alt={items[3].img.alt} />
            <h3>{items[3].title}</h3>
            <p>{items[3].description}</p>
          </button>
        </Li>
        <Li>
          <button onClick={toggleClipboard}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={items[4].img.src} alt={items[4].img.alt} />
            <h3>{items[4].title}</h3>
            <p>{items[4].description}</p>
          </button>
        </Li>
        <Li>
          <button onClick={toggleMail}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={items[5].img.src} alt={items[5].img.alt} />
            <h3>{items[5].title}</h3>
            <p>{items[5].description}</p>
          </button>
        </Li>
      </ul>
    </Section>
  )
})


const Section = styled.section<{ $dashboardPage: boolean }>`
  ${designGrid({})}
  padding-top: 37px;

  > p {
    &:last-child {
      margin-bottom: 0;
    }

    &,
    button {
      ${getIntro22()}
    }

    &:first-child,
    &:nth-child(2) {
      grid-column: 1 / span 6;
      text-align: left;

      ${mq.greaterThan('tablet')} {
        grid-column: 1 / span 6;
      }

      button,
      a {
        color: var(--color-blue);

        &,
        span {
          text-decoration: underline;
          text-decoration-color: rgba(255, 255, 255, 0);
          transition: 300ms text-decoration-color ease-out;
        }

        &:hover {
          &,
          span {
            text-decoration-color: var(--color-blue);
          }
        }
      }

      button {
        background: transparent;
        border: none;
        font-weight: 600;
      }
    }

    &:nth-child(2) {
      ${mq.greaterThan('tablet')} {
        grid-column: 7 / span 6;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    grid-column: 1 / span 6;
    justify-content: space-between;
    margin-top: ${({ $dashboardPage }) => $dashboardPage ? '50px' : '38px'};
    text-align: left;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 12;
    }
  }
`


const Li = styled.li`
  margin-bottom: ${vw(20, 'mobile')};
  width: calc(50% - ${vw(10, 'mobile')});

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(20, 'desktop')};
    width: calc(${100 / 3}% - ${vw((38 / 3) * 2, 'desktop')});
  }

  img {
    height: 30px;
    width: 30px;
  }

  a,
  button {
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid var(--color-grey);
    border-radius: 3.95px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    transition: 300ms background-color ease-in-out, 300ms border-color ease-in-out, 300ms box-shadow ease-in-out;
    width: 100%;

    p {
      color: var(--color-text);
    }

    &:hover {
      background-color: white;
      border-color: white;
      box-shadow: 0 7px 14px 0 rgba(0, 0, 0, .05);
    }
  }

  a {
    text-decoration: none;
  }

  h3 {
    color: var(--color-blue);
    font-weight: 500;
    margin-bottom: 5px;
  }

  h3,
  p {
    font-size: 13px;
    line-height: 13px;
    text-align: left;
  }

  img {
    margin-bottom: 15px;
  }
`
