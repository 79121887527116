import { NextResponse } from 'next/server'
import type { NextRequest } from 'next/server'
import { redirectBaseOnUserRole, permissionsByRole } from '@/utils'
// HOSTNAME, DEV

export const checkIfPublicPath = (pathname) => {
  if (pathname.startsWith('/login') ||
      pathname.startsWith('/signup') ||
      pathname.startsWith('/reset-password') ||
      pathname.startsWith('/404') ||
      pathname.startsWith('/not-found') ||
      pathname.startsWith('/mantenimiento')) {
    return true
  }
}

export const checkIfLegalPath = (pathname) => {
  if (pathname.startsWith('/rgpd') ||
      pathname.startsWith('/condiciones-de-uso') ||
      pathname.startsWith('/politica-de-privacidad') ||
      pathname.startsWith('/acuerdo-comercial')) {
    return true
  }
}

// This function can be marked `async` if using `await` inside
export function middleware(request: NextRequest) {
  // Redirect to maintenance page if the app is in maintenance mode
  const maintenance = process.env.DY_MAINTENANCE === 'true'
  if (maintenance) {
    if(request.nextUrl.pathname !== '/mantenimiento') {
      return NextResponse.redirect(new URL('/mantenimiento', request.url))
    } else {
      const response = NextResponse.next()
      return response
    }
  }


  const userCookie = request.cookies.get('DY_VIRBAC_USER')
  const isPublicPath = checkIfPublicPath(request.nextUrl.pathname)
  const isLegalPath = checkIfLegalPath(request.nextUrl.pathname)
  // const queryParams = request.nextUrl.searchParams

  if(isPublicPath && userCookie) {
    const href = redirectBaseOnUserRole(userCookie, null, true)
    return NextResponse.redirect(new URL(href, request.url))
  } else if (!isPublicPath && !isLegalPath && !userCookie) {
    // ❌ Trying to access a private path without credentials'
    return NextResponse.redirect(new URL('/login', request.url))
  } else if (!isPublicPath && !isLegalPath  && userCookie) {
    // 🟢 !isPublicPath && userCookie >>> checking ROLES permissions'
    const userData = JSON.parse(userCookie.value)
    const role = userData?.user?.role
    console.info('🛡️ role', role)
    if (!role) return NextResponse.redirect(new URL('/login', request.url))
    const permissionGranted = permissionsByRole[role].some(value => request.url.includes(value))
    if (!permissionGranted) {
      const href = redirectBaseOnUserRole(userCookie, null, true)
      return NextResponse.redirect(new URL(href, request.url))
    }
  }
}

export const config = {
  matcher: [
    /*
     * Match all request paths except for the ones starting with:
     * - api (API routes)
     * - _next/static (static files)
     * - _next/image (image optimization files)
     * - favicon.ico (favicon file)
     */
    '/((?!api|_next/static|_next/image|favicon.ico|images).*)',
  ],
}
